// src/components/Inventory.js
import React, { useEffect, useState } from 'react';
import CarCard from './CarCard';
// import SearchBar from './SearchBar';
// import Pagination from './Pagination';
import './styles/Inventory.css';

// Import Firebase Firestore SDK
import { collection, query, orderBy, limit, getDocs, startAfter } from 'firebase/firestore';
import { db } from '../firebase/config'; // Adjust the path based on your project structure

const Inventory = () => {
  const [cars, setCars] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCars, setTotalCars] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [filters, setFilters] = useState({});
  const [lastVisible, setLastVisible] = useState(null); // For pagination

  const CAR_LIMIT = 10;

  // Fetch total number of cars once on component mount
  useEffect(() => {
    const fetchTotalCars = async () => {
      try {
        const carsRef = collection(db, 'cars');
        const totalSnapshot = await getDocs(carsRef);
        setTotalCars(totalSnapshot.size);
        setTotalPages(Math.ceil(totalSnapshot.size / CAR_LIMIT));
      } catch (err) {
        console.error('Error fetching total cars:', err);
        // Optional: Handle error for total cars
      }
    };

    fetchTotalCars();
  }, []); // Empty dependency array ensures this runs once

  useEffect(() => {
    const fetchCars = async () => {
      try {
        setLoading(true);
        setError(null);

        // Reference to 'cars' collection
        const carsRef = collection(db, 'cars');

        // Create a query against the collection
        let carsQuery = query(carsRef, orderBy('price'), limit(CAR_LIMIT));

        // Apply filters if any
        // Example: if filters.make exists, add a where clause
        // Adjust based on your filtering needs
        // import { where } from 'firebase/firestore';
        // if (filters.make) {
        //   carsQuery = query(carsQuery, where('make', '==', filters.make));
        // }

        // If it's a subsequent page, start after the last visible document
        if (currentPage > 1 && lastVisible) {
          carsQuery = query(carsRef, orderBy('price'), startAfter(lastVisible), limit(CAR_LIMIT));
        }

        // Execute the query
        const querySnapshot = await getDocs(carsQuery);

        // Map through documents and set state
        const fetchedCars = [];
        querySnapshot.forEach((doc) => {
          fetchedCars.push({ id: doc.id, ...doc.data() });
        });

        setCars(fetchedCars);

        // Update lastVisible for pagination
        if (querySnapshot.docs.length > 0) {
          const lastVisibleDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
          setLastVisible(lastVisibleDoc);
        }
      } catch (err) {
        console.error('Error fetching cars:', err);
        setError('Failed to fetch car inventory.');
        setCars([]);
        // setTotalCars(0); // Do not reset totalCars here
        // setTotalPages(1); // Do not reset totalPages here
      } finally {
        setLoading(false);
      }
    };

    fetchCars();
  }, [currentPage, filters]); // Removed lastVisible from dependencies

  const handleSearch = (searchFilters) => {
    setFilters(searchFilters);
    setCurrentPage(1);
    setLastVisible(null); // Reset pagination
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prevPage => prevPage - 1);
    }
  };

  if (loading) {
    return <div className="loading-spinner">Loading...</div>;
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="inventory-container">
      <h1>Find Your Future Car</h1>
      
      {/* Search Bar Component (Uncomment and implement if available) */}
      {/* <SearchBar onSearch={handleSearch} /> */}

      {/* Car List */}
      <div className="car-list">
        {cars.length > 0 ? (
          cars.map(car => <CarCard key={car.id} car={car} />)
        ) : (
          <p>No cars found matching your criteria.</p>
        )}
      </div>

      {/* Pagination Controls */}
      {totalPages > 1 && (
        <div className="pagination-controls">
          <button onClick={handlePrevPage} disabled={currentPage === 1}>
            Previous
          </button>
          <span>Page {currentPage} of {totalPages}</span>
          <button onClick={handleNextPage} disabled={currentPage === totalPages}>
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default Inventory;
