// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';



// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAKLFlWKSs1IQsg5FwkCA6ef_LYrc5Iw5w",
  authDomain: "ks-trade.firebaseapp.com",
  projectId: "ks-trade",
  storageBucket: "ks-trade.firebasestorage.app",
  messagingSenderId: "883029769576",
  appId: "1:883029769576:web:d01d50d37d25c28f10eadd",
  measurementId: "G-PZSKFNF74V"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const db = getFirestore(app)

const auth = getAuth(app)

const storage = getStorage(app);

export {app, db, auth, storage}