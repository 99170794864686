import React from 'react';
import './styles/Footer.css'; // Create and style as needed

function Footer() {
  return (
    <footer className="footer">
      <p>&copy; {new Date().getFullYear()} KS TRADE INC. All rights reserved.</p>
      {/* Add social media links or other footer content */}
    </footer>
  );
}

export default Footer;
