// src/App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from './components/Home';
import ApplyFinancing from './components/ApplyFinancing';
import TitleLoan from './components/TitleLoan';
import Inventory from './components/Inventory'; // Import Inventory Component
import CarDetail from './components/CarDetail'; // Import CarDetail Component
import Header from './components/Header';
import Footer from './components/Footer';
import Contact from './components/Contact';

function App() {
  return (
    <Router>
      <Header />
      <div className="container">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/apply-financing" element={<ApplyFinancing />} />
          <Route path="/title-loan" element={<TitleLoan />} />
          <Route path="/inventory" element={<Inventory />} />
          <Route path="/inventory/:id" element={<CarDetail />} /> {/* New Route for Car Details */}
          <Route path="/contact" element={<Contact />} />
          {/* Redirect any unknown routes to Home */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
