import React, { useState } from 'react';
import { collection, addDoc } from 'firebase/firestore'; // Import Firestore methods
import { db } from '../firebase/config'; // Import your Firebase configuration
import './styles/ApplyFinancing.css'; // Ensure this file exists and is correctly styled
import { Timestamp } from 'firebase/firestore';

function ApplyFinancing() {
  const [formData, setFormData] = useState({
    lastName: '',
    firstName: '',
    birthDate: '',
    cellPhone: '',
    driverLicenseNumber: '',
    socialSecurityNumber: '',
    address: '',
    howLongAtAddressYears: '',
    howLongAtAddressMonths: '',
    employerName: '',
    employerAddress: '',
    employerNumber: '',
    occupationOrRank: '', // New field for occupation or rank
    howLongAtEmployerYears: '',
    howLongAtEmployerMonths: '',
    monthlyIncome: '',
    incomeMethod: '', // New field for income method

  });

  const [message, setMessage] = useState('');
  const [consent, setConsent] = useState('');

  const formatPhoneNumber = (value) => {
    const cleaned = ('' + value).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]})-${match[2]}-${match[3]}`;
    }
    return value;
  };

  const formatSSN = (value) => {
    const cleaned = ('' + value).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{2})(\d{4})$/);
    if (match) {
      return `${match[1]}-${match[2]}-${match[3]}`;
    }
    return value;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'cellPhone' || name === 'employerNumber')  {
      setFormData({ ...formData, [name]: formatPhoneNumber(value) });
    } else if (name === 'socialSecurityNumber') {
      setFormData({ ...formData, [name]: formatSSN(value) });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (consent !== 'Yes') {
      setMessage('You must provide your consent to proceed.');
      return;
    }
    try {
      const applicationsRef = collection(db, 'financing_applications');

      await addDoc(applicationsRef, {
        ...formData,
        submittedAt: Timestamp.now(),
      });

      setMessage('Thank you for your application! We will reach out to you as soon as possible :)');
      setFormData({
        lastName: '',
        firstName: '',
        birthDate: '',
        driverLicenseNumber: '',
        socialSecurityNumber: '',
        address: '',
        howLongAtAddressYears: '',
        howLongAtAddressMonths: '',
        cellPhone: '',
        employerName: '',
        employerAddress: '',
        employerNumber: '',
        occupationOrRank: '', // Reset field
        howLongAtEmployerYears: '',
        howLongAtEmployerMonths: '',
        monthlyIncome: '',
        incomeMethod: '', // Reset field
      });
      setConsent('');
    } catch (error) {
      console.error('Error submitting application:', error);
      setMessage('There was an error submitting your application.');
    }
  };

  const renderMonthOptions = () => {
    return Array.from({ length: 12 }, (_, i) => (
      <option key={i} value={i}>
        {i} months
      </option>
    ));
  };

  return (
    <div className="apply-financing">
      <h2>Apply for Financing</h2>
      {message && <p className="message">{message}</p>}
      <form onSubmit={handleSubmit}>
        <div className="responsive-row-name">
          <div className="responsive-input">
            <label>First Name:</label>
            <input type="text" name="firstName" value={formData.firstName} onChange={handleChange} required />
          </div>
          <div className="responsive-input">
            <label>Last Name:</label>
            <input type="text" name="lastName" value={formData.lastName} onChange={handleChange} required />
          </div>
        </div>

        <label>Birthdate:</label>
        <input type="date" name="birthDate" value={formData.birthDate} onChange={handleChange} required />

        <label>Cell Phone:</label>
        <input type="text" name="cellPhone" value={formData.cellPhone} onChange={handleChange} required />

        <label>Driver License Number:</label>
        <input type="text" name="driverLicenseNumber" value={formData.driverLicenseNumber} onChange={handleChange} required />

        <label>Social Security Number:</label>
        <input type="text" name="socialSecurityNumber" value={formData.socialSecurityNumber} onChange={handleChange} required />

        <label>Address:</label>
        <input type="text" name="address" value={formData.address} onChange={handleChange} required />

        <label>How Long? (at Address):</label>
        <div className="responsive-row-long">
          <div className="responsive-input">
            <label>Years:</label>
            <input
              type="text"
              name="howLongAtAddressYears"
              value={formData.howLongAtAddressYears}
              onChange={handleChange}
              
            />
          </div>
          <div className="responsive-input">
            <label>Months:</label>
            <select
              name="howLongAtAddressMonths"
              value={formData.howLongAtAddressMonths}
              onChange={handleChange}
              required
            >
              <option value="">Select</option>
              {renderMonthOptions()}
            </select>
          </div>
        </div>

        <label>Employer Name:</label>
        <input type="text" name="employerName" value={formData.employerName} onChange={handleChange} required />

        <label>Employer Address:</label>
        <input type="text" name="employerAddress" value={formData.employerAddress} onChange={handleChange} required />

        <label>Employer Number:</label>
        <input type="text" name="employerNumber" value={formData.employerNumber} onChange={handleChange} required />

        <label>Occupation or Rank:</label>
        <input
          type="text"
          name="occupationOrRank"
          value={formData.occupationOrRank}
          onChange={handleChange}
          placeholder="e.g., Manager, Developer, etc."
          required
        />

        <label>How Long? (at Employer):</label>
        <div className="responsive-row-long">
          <div className="responsive-input">
            <label>Years:</label>
            <input
              type="text"
              name="howLongAtEmployerYears"
              value={formData.howLongAtEmployerYears}
              onChange={handleChange}
              
            />
          </div>
          <div className="responsive-input">
            <label>Months:</label>
            <select
              name="howLongAtEmployerMonths"
              value={formData.howLongAtEmployerMonths}
              onChange={handleChange}
              required
            >
              <option value="">Select</option>
              {renderMonthOptions()}
            </select>
          </div>
        </div>

        <label>Monthly Income:</label>
        <input
          type="number"
          name="monthlyIncome"
          placeholder="$"
          value={formData.monthlyIncome}
          onChange={handleChange}
          required
        />

        <label>Income Method:</label>
        <select
          name="incomeMethod"
          value={formData.incomeMethod}
          onChange={handleChange}
          required
        >
          <option value="">Select Method</option>
          <option value="cash">Cash</option>
          <option value="check">Check</option>
          <option value="directDeposit">Direct Deposit</option>
        </select>



        <div className="acknowledgement">
          <h3>Acknowledgement and Consent</h3>
          <p>
            By submitting this application, you certify that all the statements and information provided in this
            application are true, accurate, and complete, and are made for the purpose of obtaining credit. You further
            authorize us to obtain information from your personal credit profile as part of the prequalification process.
          </p>
          <p>
            You acknowledge that this form, which you have filled out on our website, is not a loan application but a
            request to obtain prequalification. Furthermore, you confirm your understanding that any pre-approval or
            prequalification is subject to a detailed review and acceptance of your credit information.
          </p>
        </div>

        <label>Do you consent to the terms above?</label>
        <select
          name="consent"
          value={consent}
          onChange={(e) => setConsent(e.target.value)}
          required
        >
          <option value="">Select</option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>

        <button type="submit">Submit Application</button>
      </form>
    </div>
  );
}

export default ApplyFinancing;
