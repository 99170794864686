import React from 'react';
import { Link } from 'react-router-dom';
import './styles/Home.css';
import SupportChat from './SupportChat';

function Home() {
  return (
    <div className="home">
      {/* Hero Section */}
      <section className="hero">
        <div className="hero-content">
          <h1>Inventory</h1>
          <p>Find Your Future Car</p>
          <div className="hero-buttons">
            <Link to="/inventory" className="secondary-button">Go to Inventory</Link>
          </div>
        </div>
        <div className="hero-image"></div> {/* Empty div for CSS background */}
      </section>

      {/* Comparison Section */}
      <section className="comparison">
        <div className="comparison-image"></div> {/* Empty div for CSS background */}
        <div className="comparison-content">
          <h1>Financing</h1>
          <p>Quick and Simple Auto Loan</p>
          <Link to="/apply-financing" className="primary-button">Click to Get Quote</Link>
        </div>
      </section>

      {/* Support Chat Section 
      <section className="support-section">
        <h1>Contact Support</h1>
        <SupportChat />
      </section>*/}
    </div>
  );
}

export default Home;
