import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase/config';
import './styles/CarDetail.css';
import defaultCarImage from '../images/car.jpg';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const CarDetail = () => {
  const { id } = useParams(); // Extract the car ID from the URL
  const [car, setCar] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  // Function to remove transform and transition styles for thumbnails
  const adjustThumbnailsStyle = () => {
    const thumbsElement = document.querySelector('.thumbs.animated');
    if (thumbsElement && window.innerWidth <= 768) {
      thumbsElement.style.transform = 'none';
      thumbsElement.style.transition = 'none';
    }
  };

  useEffect(() => {
    const fetchCarDetails = async () => {
      try {
        setLoading(true);
        setError(false);

        // Reference to the specific car document
        const carRef = doc(db, 'cars', id);
        const carSnap = await getDoc(carRef);

        if (carSnap.exists()) {
          setCar({ id: carSnap.id, ...carSnap.data() });
        } else {
          console.error('No such document!');
          setError(true);
        }
      } catch (err) {
        console.error('Error fetching car details:', err);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchCarDetails();

    // Adjust thumbnails style on component mount and window resize
    adjustThumbnailsStyle();
    window.addEventListener('resize', adjustThumbnailsStyle);

    // Cleanup event listener
    return () => {
      window.removeEventListener('resize', adjustThumbnailsStyle);
    };
  }, [id]);

  if (loading) {
    return <div className="loading-spinner">Loading...</div>;
  }

  if (error || !car) {
    return <div className="error-message">Failed to load car details.</div>;
  }

  const {
    make,
    model,
    year,
    price,
    mileage,
    color,
    transmission,
    cylinders,
    Title,
    MPG,
    Options = [],
    images = [], // Array of public URLs for all images
  } = car;

  return (
    <div className="car-detail-container">
      <button onClick={() => window.history.back()} className="back-button">
        Back to Inventory
      </button>

      <Carousel showThumbs={true} infiniteLoop={true}>
        {images.map((url, index) => (
          <div key={index}>
            <img
              src={url}
              alt={`Car Image ${index + 1}`}
            />
          </div>
        ))}
      </Carousel>

      {/* Car Details */}
      <div className="car-details">
        <h2>{`${make} ${model} (${year})`}</h2>
        <p><strong>Price:</strong> ${price.toLocaleString()}</p>
        <p><strong>Mileage:</strong> {mileage.toLocaleString()} mi</p>
        <p><strong>Color:</strong> {color}</p>
        <p><strong>Transmission:</strong> {transmission}</p>
        <p><strong>Cylinders:</strong> {cylinders}</p>
        <p><strong>Title Status:</strong> {Title}</p>
        <p><strong>MPG:</strong> {MPG || 'N/A'}</p>
        <div className="options">
          <strong>Options:</strong>
          {Options && Array.isArray(Options) ? (
            <ul>
              {Options.map((option, index) => (
                <li key={index}>{option}</li>
              ))}
            </ul>
          ) : (
            <p>No additional Options available.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CarDetail;
