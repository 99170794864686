// frontend/src/components/TitleLoan.js

import React, { useState } from 'react';
import axios from 'axios';
import './styles/TitleLoan.css'; // Ensure this file exists and is correctly styled

function TitleLoan() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    vehicle: '',
    vehicleYear: '',
    vehicleValue: '',
    loanAmount: 5000, // Default value for slider
    ownershipStatus: '', // 'Owned' or 'Financed'
    insurance: false,
    extendedWarranty: false,
    // Add other necessary fields
  });

  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setFormData({ ...formData, [name]: checked });
    } else if (type === 'range') {
      setFormData({ ...formData, [name]: Number(value) });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Optional: Additional frontend validation can be added here
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/title-loans`, formData);
      setMessage('Title Loan application submitted successfully!');
      setFormData({
        name: '',
        email: '',
        phone: '',
        vehicle: '',
        vehicleYear: '',
        vehicleValue: '',
        loanAmount: 5000,
        ownershipStatus: '',
        insurance: false,
        extendedWarranty: false,
      });
    } catch (error) {
      if (error.response && error.response.data) {
        if (error.response.data.errors) {
          setMessage(`Validation Error: ${error.response.data.errors.join(', ')}`);
        } else {
          setMessage(`Error: ${error.response.data.message}`);
        }
      } else {
        setMessage('There was an error submitting your application.');
      }
    }
  };

  return (
    <div className="title-loan">
      <h2>자동차 담보 대출 한도 조회</h2>
      {message && <p className="message">{message}</p>}
      <form onSubmit={handleSubmit}>
        {/* Name */}
        <label htmlFor="name">이름:</label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
        />

        {/* Email */}
        <label htmlFor="email">Email:</label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />

        {/* Phone */}
        <label htmlFor="phone">연락처:</label>
        <input
          type="tel"
          id="phone"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          required
        />

        {/* Vehicle Type */}
        <label htmlFor="vehicle">차 종류:</label>
        <select
          id="vehicle"
          name="vehicle"
          value={formData.vehicle}
          onChange={handleChange}
          required
        >
          <option value="">차 종류를 선택하세요</option>
          <option value="Car">Car</option>
          <option value="Truck">Truck</option>
          <option value="SUV">SUV</option>
          <option value="Motorcycle">Motorcycle</option>
          <option value="Van">Van</option>
        </select>

        {/* Vehicle Year */}
        <label htmlFor="vehicleYear">연식:</label>
        <select
          id="vehicleYear"
          name="vehicleYear"
          value={formData.vehicleYear}
          onChange={handleChange}
          required
        >
          <option value="">연식을 선택하세요</option>
          {Array.from({ length: 31 }, (_, i) => new Date().getFullYear() - i).map((year) => (
            <option key={year} value={year}>{year}</option>
          ))}
        </select>

        {/* Vehicle Value */}
        <label htmlFor="vehicleValue">차 가격:</label>
        <select
          id="vehicleValue"
          name="vehicleValue"
          value={formData.vehicleValue}
          onChange={handleChange}
          required
        >
          <option value="">차 가격을 선택하세요</option>
          <option value="5000">Under $5,000</option>
          <option value="10000">$5,000 - $10,000</option>
          <option value="15000">$10,000 - $15,000</option>
          <option value="20000">Over $15,000</option>
        </select>

        {/* Loan Amount */}
        <label htmlFor="loanAmount">대출 희망 액수: ${formData.loanAmount}</label>
        <input
          type="range"
          id="loanAmount"
          name="loanAmount"
          min="1000"
          max="10000"
          step="500"
          value={formData.loanAmount}
          onChange={handleChange}
          required
        />

        {/* Ownership Status */}
        <label>차 소유:</label>
        <div className="radio-group">
          <input
            type="radio"
            id="owned"
            name="ownershipStatus"
            value="Owned"
            checked={formData.ownershipStatus === 'Owned'}
            onChange={handleChange}
            required
          />
          <label htmlFor="owned">소유</label>

          <input
            type="radio"
            id="financed"
            name="ownershipStatus"
            value="Financed"
            checked={formData.ownershipStatus === 'Financed'}
            onChange={handleChange}
            required
          />
          <label htmlFor="financed">대출</label>
        </div>

        {/* Additional Services */}
        {/* <label>Additional Services:</label>
        <div className="checkbox-group">
          <input
            type="checkbox"
            id="insurance"
            name="insurance"
            checked={formData.insurance}
            onChange={handleChange}
          />
          <label htmlFor="insurance">Insurance</label>

          <input
            type="checkbox"
            id="extendedWarranty"
            name="extendedWarranty"
            checked={formData.extendedWarranty}
            onChange={handleChange}
          />
          <label htmlFor="extendedWarranty">Extended Warranty</label>
        </div> */}

        {/* Submit Button */}
        <button type="submit">한도 조회</button>
      </form>
    </div>
  );
}

export default TitleLoan;
